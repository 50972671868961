import { useEffect, useState } from "react";
import styles from "./Modal.module.scss";
import { useModal } from "./useModal";

const ModelItem = ({ layer }) => {
  const { hideModal } = useModal();
  const { id, modal, isShown } = layer;
  const { title, type, content } = modal;

  return (
    <div
      className={`${styles.modal} ${isShown ? styles["is-shown"] : ""} ${
        styles[type]
      }`}
    >
      <div className={styles.close} onClick={() => hideModal(id)}></div>

      <div className={styles.content}>{content}</div>
    </div>
  );
};

const Modal = () => {
  const { layers, hideModal } = useModal([]);
  const [showBackground, setShowBackground] = useState(false);

  useEffect(() => {
    if (!layers?.length) {
      document.body.classList.remove("modal-open");
      setTimeout(() => setShowBackground(false), 300);
      return;
    }

    document.body.classList.add("modal-open");
    setTimeout(() => setShowBackground(true));
  }, [layers]);

  if (!layers?.length) {
    if (!showBackground) {
      return <></>;
    }

    return <div className={styles.backdrop}></div>;
  }

  const handleBackdropClick = (e) => {
    const backdropClass = styles.backdrop;
    const classes = Array.from(e.target.classList);
    if (classes.some((x) => x === backdropClass)) {
      hideModal();
    }
  };

  return (
    <div
      className={`${styles["backdrop"]} ${styles["is-shown"]}`}
      onClick={handleBackdropClick}
    >
      {layers?.length ? (
        layers.map((layer) => <ModelItem key={layer.id} layer={layer} />)
      ) : (
        <></>
      )}
    </div>
  );
};

export default Modal;
