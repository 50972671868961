import { useEffect, useState } from "react";
import styles from "./Input.module.scss";
import InputDate from "./input-date/InputDate";
import { v4 as uuidv4 } from "uuid";

const Input = ({ label, value, onChange, type = "text", ...props }) => {
  const [id, setId] = useState();

  useEffect(() => {
    setId(uuidv4());
  }, []);

  useEffect(() => {
    if (id) {
      const input = document.getElementById(id);
      input.value = value ?? "";
    }
  }, [value, id]);

  if (type === "date") {
    return (
      <label className={`${styles.input} input`}>
        <span className={`${styles.label} label`}>{label}</span>
        <InputDate
          id={id}
          defaultValue={value}
          onChange={onChange}
          {...props}
        />
      </label>
    );
  }

  return (
    <label className={`${styles.input} input`}>
      <span className={`${styles.label} label`}>{label}</span>
      <input
        id={id}
        type={type}
        defaultValue={value}
        onChange={onChange}
        {...props}
      />
    </label>
  );
};

export default Input;
