import styles from "./Button.module.scss";

const Button = ({
  children,
  type = "default",
  className = "",
  icon = null,
  iconOnly = false,
  onClick,
  ...props
}) => {
  return (
    <button
      className={`${styles.button} ${
        type !== "default" && styles[type.toLocaleLowerCase()]
      } button ${icon && styles["has-icon"]} ${className} ${
        iconOnly ? styles["icon-only"] : ""
      }`}
      type={type}
      onClick={onClick}
      {...props}
    >
      {icon && (
        <i className={`material-symbols-outlined ${styles.icon}`}>{icon}</i>
      )}
      {children}
    </button>
  );
};

export default Button;
