import { useState } from "react";
import { useBetween } from "use-between";
import { v4 as uuidv4 } from "uuid";

const useModalContext = () => {
  const [layers, setLayers] = useState([]);

  const showModal = (modal) => {
    const wrapper = getNewModal(modal);
    setLayers((prev) => [wrapper, ...prev]);

    setTimeout(() => {
      setLayers((prev) => {
        const index = prev.findIndex((layer) => layer.id === wrapper.id);
        const item = prev[index];

        return [
          ...prev.slice(0, index),
          { ...item, isShown: true },
          ...prev.slice(index + 1),
        ];
      });
    }, 300);

    return wrapper.id;
  };

  const getNewModal = (modal) => {
    const id = uuidv4();

    return {
      id,
      modal,
      isShown: false,
    };
  };

  const hideModal = (id = null) => {
    if (!id) {
      id = layers[0].id;
    }

    setLayers((prev) => {
      const index = prev.findIndex((layer) => layer.id === id);
      const item = prev[index];

      return [
        ...prev.slice(0, index),
        { ...item, isShown: false },
        ...prev.slice(index + 1),
      ];
    });

    setTimeout(() => {
      setLayers((prev) => [...prev.filter((x) => x.id !== id)]);
    }, 300);
  };

  return {
    layers,

    showModal,
    hideModal,
  };
};

export const useModal = () => useBetween(useModalContext);
