import { useModal } from "shared/components/modal/useModal";
import useAttributes from "shared/hooks/useAttributes";

const MarkDownImage = ({ node, children }) => {
  const { src, ...props } = node.properties;
  const newProps = useAttributes(props);
  const { showModal } = useModal();

  return (
    <img
      src={src}
      className="preview"
      {...newProps}
      onClick={() => {
        showModal({
          title: "sds",
          type: "image",
          content: <img src={src} />,
        });
      }}
    />
  );
};

export default MarkDownImage;
