import styles from "./Navbar.module.scss";
import { useEffect } from "react";
import Loader from "../../loader/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../../forms/button/Button";
import { useSharedProjects } from "states/Projects";
import { ReactSortable } from "react-sortablejs";
import { arrayMoveImmutable } from "array-move";

const Navbar = ({ loaded }) => {
  const { isAuthenticated, authIsLoading } = useAuth0();
  const { projects, order } = useSharedProjects();
  const navigate = useNavigate();

  useEffect(() => {
    if (authIsLoading === false) {
      loaded(true);
      return;
    }

    loaded(false);
  }, [authIsLoading, loaded]);

  if (authIsLoading) {
    return <Loader />;
  }

  const isActiveUrl = (isActive, slug) => {
    if (isActive) {
      return styles.active;
    }

    if (window.location.pathname.startsWith("/admin/")) {
      const urlSlug = window.location.pathname.split("/")[3];

      return slug === urlSlug ? styles.active : "";
    }

    return null;
  };

  const handleReorder = async ({ oldIndex, newIndex }) => {
    if (!isAuthenticated) {
      return;
    }

    const ordered = arrayMoveImmutable(projects, oldIndex, newIndex);
    ordered.forEach((project, index) => (project.index = index));

    await order(ordered);
  };

  const notPublishedProjects = (name, query) => {
    if (!isAuthenticated || !projects?.length) {
      return;
    }

    const notPublished = projects.filter(query);
    if (!notPublished.length) {
      return;
    }

    return (
      <div className={styles["not-published"]}>
        <div className={styles.title}>{name}</div>
        {notPublished.map((project) => (
          <div key={project.id} className={styles.item}>
            <NavLink
              to={`/projects/${project.slug}`}
              className={({ isActive }) => isActiveUrl(isActive, project.slug)}
            >
              {project.name}
            </NavLink>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.navbar}>
      <div className={styles.projects}>
        {!projects?.length && (
          <div className={styles["no-items"]}>Geen projecten gevonden</div>
        )}

        {isAuthenticated ? (
          <ReactSortable
            tag="ul"
            className={styles.group}
            list={projects}
            setList={() => {}}
            onEnd={handleReorder}
          >
            {projects?.length > 0 &&
              projects
                .filter((x) => x.isPublished && !x.isPrivate)
                .map((project) => (
                  <li key={project.id} className={styles.item}>
                    <NavLink
                      to={`/projects/${project.slug}`}
                      className={({ isActive }) =>
                        isActiveUrl(isActive, project.slug)
                      }
                    >
                      {project.name}
                    </NavLink>
                  </li>
                ))}
          </ReactSortable>
        ) : (
          projects?.length > 0 &&
          projects
            .filter((x) => x.isPublished)
            .map((project) => (
              <div key={project.id} className={styles.item}>
                <NavLink
                  to={`/projects/${project.slug}`}
                  className={({ isActive }) =>
                    isActiveUrl(isActive, project.slug)
                  }
                >
                  {project.name}
                </NavLink>
              </div>
            ))
        )}
        {isAuthenticated && (
          <div className={styles.actions}>
            <Button
              type="green"
              onClick={() => navigate("/admin/projects/create")}
            >
              Add project
            </Button>
          </div>
        )}

        {notPublishedProjects("Niet gepubliceerd", (x) => !x.isPublished)}
        {notPublishedProjects("Prive", (x) => x.isPrivate)}
      </div>
    </div>
  );
};

export default Navbar;
