import { useEffect } from "react";

const callbackList = [];

const useClickOutside = (ref, callback, use = true) => {
  useEffect(() => {
    if (!use) {
      return;
    }

    callbackList[ref] = callback;

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback, use]);
};

export default useClickOutside;
