import styles from "./Project.module.scss";
import { NavLink, Navigate, useNavigate, useParams } from "react-router-dom";
import Loader from "../../shared/components/loader/Loader";
import Markdown from "../../shared/components/markdown/Markdown";
import { useEffect, useState } from "react";
import { useSharedProjects } from "states/Projects";
import axios from "axios";
import useShortcut from "shared/hooks/useShortcut";
import { useSharedRoute } from "states/Route";

const Project = () => {
  const { slug, pageNum: pageStr } = useParams();
  const { selected, select } = useSharedProjects();
  const navigate = useNavigate();
  const [pages, setPages] = useState([]);
  const [pagesLoaded, setPagesLoaded] = useState(false);
  const sharedRoutes = useSharedRoute();

  useEffect(() => {
    sharedRoutes.setParams({ slug, pageNum: pageStr });
  }, [slug, pageStr]);

  useShortcut(
    "ArrowLeft",
    () => {
      navigate(`/projects/${slug}/${parseInt(pageStr) - 1}`);
    },
    pageStr && parseInt(pageStr) > 1
  );

  useShortcut(
    "ArrowRight",
    () => {
      navigate(`/projects/${slug}/${parseInt(pageStr) + 1}`);
    },
    pageStr && parseInt(pageStr) < pages.length
  );

  useEffect(() => {
    setPages([]);
    setPagesLoaded(false);

    if (!slug) {
      return;
    }

    const res = select(slug);
    if (!res) {
      return navigate("/error/404");
    }
  }, [slug]);

  useEffect(() => {
    if (!selected) {
      return;
    }

    const fetch = async () => {
      if (selected.slug === "not-found") {
        const { data } = await axios.get(
          "https://v2.jokeapi.dev/joke/Programming?amount=10&type=single"
        );

        const jokes = data.jokes.map((x, idx) => ({
          index: idx,
          content: "```\n" + x.joke + "\n```",
        }));

        setPages(jokes);
        setPagesLoaded(true);
        return;
      }

      setPages(selected.pages);
      setPagesLoaded(true);
    };

    fetch();
  }, [selected, setPages, setPagesLoaded]);

  useEffect(() => {
    if (!pageStr) {
      navigate(`/projects/${slug}/1`);
      return;
    }
  }, [pageStr, navigate, slug]);

  if (!pagesLoaded || !pageStr) {
    return <Loader />;
  }

  if (!selected) {
    return <Navigate to="/error/404" />;
  }

  const pageNum = parseInt(pageStr);
  const page = pages.find((page) => page.index === pageNum - 1);
  if (!page && pagesLoaded && pages.length > 0) {
    return <Navigate to={`/projects/${slug}/1`} />;
  }

  const isGithubUrl = (url) => {
    return url.startsWith("https://github.com");
  };

  const isAzureUrl = (url) => {
    return url.startsWith("https://dev.azure.com");
  };

  const showNextPageBtn = () => {
    return pages?.length > 1 && pageNum < pages.length;
  };

  const showPrevPageBtn = () => {
    return pageNum > 1;
  };

  return (
    <div className={styles.project}>
      <div className={styles.header}>
        <div className={styles.intro}>
          <div className={styles.title}>
            <h1>{selected.name}</h1>
            <div className={styles.namespace}>{selected.namespace}</div>

            {selected.url && (
              <div className={styles.link}>
                <NavLink to={selected.url} target="_blank">
                  {isGithubUrl(selected.url) && (
                    <span className={styles.github}></span>
                  )}
                  {isAzureUrl(selected.url) && (
                    <span className={styles.azure}></span>
                  )}

                  {!isGithubUrl(selected.url) && !isAzureUrl(selected.url) && (
                    <span className="material-symbols-outlined">link</span>
                  )}
                </NavLink>
              </div>
            )}
          </div>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: selected.description }}
          ></div>
        </div>
      </div>
      {!pagesLoaded && <Loader />}
      {pagesLoaded && (
        <>
          <div className={styles.pages}>
            {page && (
              <Markdown content={page.content} className={styles.page} />
            )}
          </div>

          {showPrevPageBtn() || showNextPageBtn() ? (
            <>
              {showPrevPageBtn() && (
                <NavLink
                  className={styles.previous}
                  to={`/projects/${slug}/${pageNum - 1}`}
                >
                  <span className={`material-symbols-outlined ${styles.icon}`}>
                    arrow_back_ios
                  </span>
                </NavLink>
              )}
              {showNextPageBtn() && (
                <NavLink
                  className={styles.next}
                  to={`/projects/${slug}/${pageNum + 1}`}
                >
                  <span className={`material-symbols-outlined ${styles.icon}`}>
                    arrow_forward_ios
                  </span>
                </NavLink>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default Project;
