import ApiService from "./ApiService";

export default class FilesService {
  constructor() {
    this.api = new ApiService(`${process.env.REACT_APP_API_URL}/Files`);
  }

  get = async (key) => {
    return await this.api.get(`/${key}`);
  };

  create = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return await this.api.post("/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };
}
