import styles from "./PagesProject.module.scss";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Button from "shared/components/forms/button/Button";
import { useEffect, useReducer, useState } from "react";
import Loader from "shared/components/loader/Loader";
import Input from "shared/components/forms/input/Input";
import { ReactSortable } from "react-sortablejs";
import MDEditor from "@uiw/react-md-editor";
import { useSharedProjects } from "states/Projects";
import onImagePasted from "shared/utils/onImagePasted";

const PagesProject = () => {
  const { selected, setPages, select } = useSharedProjects();
  const { pageNum, slug } = useParams();

  const setPagesReducer = (state, action) => {
    const { payload } = action;
    switch (action.type) {
      case "SET":
        const pages = payload ?? [];
        if (!pages?.length) {
          pages.push({ name: "Page 1", content: "", index: 0 });
        }
        const num = parseInt(pageNum);
        return { pages: pages, selected: pages[num - 1] ?? {} };
      case "SAVE":
        return { ...state, pages: payload };
      case "MOVE":
        return { ...state, pages: payload };
      case "ADD":
        const newIndex = Math.max(...state.pages.map((x) => x.index)) + 1;
        const newPage = {
          name: `Page ${newIndex}`,
          index: newIndex,
          content: "",
        };

        return { pages: [...state.pages, newPage], selected: newPage };
      case "DELETE":
        return {
          ...state,
          pages: state.pages.filter((x) => x !== payload),
        };
      case "SELECT":
        return { ...state, selected: payload };

      case "NAME":
        const namePage = state.pages.find((x) => x === state.selected);
        namePage.name = payload;

        const namePages = [
          ...state.pages.filter((x) => x !== state.selected),
          namePage,
        ].sort((a, b) => a.index - b.index);

        return {
          pages: namePages,
          selected: namePage,
        };
      case "CONTENT":
        const contentPage = state.pages.find((x) => x === state.selected);
        contentPage.content = payload;

        const contentPages = [
          ...state.pages.filter((x) => x !== state.selected),
          contentPage,
        ].sort((a, b) => a.index - b.index);

        return {
          pages: contentPages,
          selected: contentPage,
        };
      default:
        return state;
    }
  };
  const [currentState, dispatchCurrentState] = useReducer(setPagesReducer, {
    pages: [],
    selected: null,
  });

  const [pagesLoaded, setPagesLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!pageNum || !currentState.pages.length) {
      return;
    }

    const num = parseInt(pageNum);
    if (num > currentState.pages.length) {
      navigate(`/admin/projects/${slug}/pages/${currentState.pages.length}`);
      return;
    }

    dispatchCurrentState({
      type: "SELECT",
      payload: currentState.pages[num - 1],
    });
  }, [pageNum, currentState.pages]);

  useEffect(() => {
    dispatchCurrentState({ type: "SET", payload: [] });
    setPagesLoaded(false);

    if (!slug) {
      return;
    }

    const project = select(slug);
    if (!project) {
      navigate("/error/404");
      return;
    }
  }, [slug]);

  useEffect(() => {
    if (!selected) {
      return;
    }

    const pages = selected.pages.sort((a, b) => a.index - b.index);
    dispatchCurrentState({ type: "SET", payload: pages });
    setPagesLoaded(true);
  }, [selected]);

  if (!pageNum) {
    return <Navigate to={`/admin/projects/${slug}/pages/1`} />;
  }

  if (!selected || !pagesLoaded) {
    return <Loader />;
  }

  const handleSave = async () => {
    const pages = await setPages(selected.id, currentState.pages);
    dispatchCurrentState({ type: "SAVE", payload: pages });

    navigate(`/projects/${selected.slug}/${pageNum}`);
  };

  return (
    <div className={styles["manage-pages"]}>
      <h1>pages</h1>
      <div className={styles.manage}>
        <div className={styles.pages}>
          <div className={styles.top}>
            <ReactSortable
              tag="ul"
              className={styles.group}
              list={currentState.pages}
              setList={() => {}}
              onEnd={(event) => {
                const array = [...currentState.pages];
                const temp = array[event.oldIndex];
                array[event.oldIndex] = array[event.newIndex];
                array[event.newIndex] = temp;

                array.forEach((x, index) => (x.index = index));

                dispatchCurrentState({ type: "MOVE", payload: array });
              }}
            >
              {currentState.pages.map((page) => (
                <li
                  key={page.id}
                  className={`${styles.page} ${
                    currentState.selected.index === page.index
                      ? styles.active
                      : null
                  }`}
                  onClick={() =>
                    navigate(
                      `/admin/projects/${selected.slug}/pages/${page.index + 1}`
                    )
                  }
                >
                  <span className={styles.name}>{page.name}</span>
                  <span
                    className={`material-symbols-outlined ${styles.remove}`}
                    onClick={() =>
                      dispatchCurrentState({ type: "DELETE", payload: page })
                    }
                  >
                    close
                  </span>
                </li>
              ))}
            </ReactSortable>
            <Button
              type="primary"
              onClick={() => dispatchCurrentState({ type: "ADD" })}
            >
              Add page
            </Button>
          </div>

          <Button type="green" className={styles.save} onClick={handleSave}>
            Save
          </Button>
        </div>

        <div className={styles.editor}>
          <div className={styles["name-input"]}>
            <Input
              label="Name"
              value={currentState.selected.name}
              onChange={(e) =>
                dispatchCurrentState({ type: "NAME", payload: e.target.value })
              }
            />
          </div>
          <div className="markdown-editor">
            <MDEditor
              value={currentState.selected.content}
              onChange={(value) =>
                dispatchCurrentState({ type: "CONTENT", payload: value })
              }
              onDrop={(e) => {}}
              onPaste={(e) => {
                onImagePasted(e.clipboardData, (markdown) => {
                  dispatchCurrentState({ type: "CONTENT", payload: markdown });
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagesProject;
