import { useState } from "react";
import styles from "./Layout.module.scss";
import Navbar from "./navbar/Navbar";
import TopBar from "./top-bar/TopBar";

const Layout = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className={styles.layout}>
      <TopBar />
      <Navbar loaded={(x) => setIsLoaded(!x)} />
      {isLoaded && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default Layout;
