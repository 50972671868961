import styles from "./Loader.module.scss";

const Loader = () => {
  return (
    <div className={`${styles["loader"]} loader`}>
      <div className={styles["loader__spinner"]}></div>
    </div>
  );
};

export default Loader;
