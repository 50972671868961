import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import Loader from "../components/loader/Loader";

export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => {
      return (
        <div className="page-layout">
          <Loader />
        </div>
      );
    },
    returnTo: "/",
  });

  return <Component />;
};
