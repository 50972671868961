const useAttributes = (props) => {
  const camelCaseToKebabCase = (str) => {
    const reactExceptions = ["className"];
    if (reactExceptions.some((x) => x === str)) {
      return str;
    }

    const result = str.split(/(?=[A-Z])/);
    return result.join("-").toLowerCase();
  };

  const newProps = Object.keys(props).reduce((acc, key) => {
    const newKey = camelCaseToKebabCase(key);
    acc[newKey] = props[key];
    return acc;
  }, {});

  return newProps;
};

export default useAttributes;
