import styles from "./EditProject.module.scss";
import Input from "../../../../shared/components/forms/input/Input";
import Button from "../../../../shared/components/forms/button/Button";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../../shared/components/alert/Alert";
import { useSharedProjects } from "states/Projects";
import Loader from "shared/components/loader/Loader";
import Checkbox from "shared/components/forms/checkbox/Checkbox";

const EditProject = () => {
  const { update, select, selected } = useSharedProjects();
  const { slug } = useParams();

  const [error, setError] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), 15000);
    }
  }, [error]);

  useEffect(() => {
    if (!slug) {
      return;
    }

    const project = select(slug);
    if (!project) {
      navigate("/error/404");
    }
  }, [slug]);

  useEffect(() => {
    if (!selected) {
      return;
    }
  }, [selected]);

  if (!selected) {
    return <Loader />;
  }

  const handleSave = async (e) => {
    e.preventDefault();

    const { name, description, namespace, url, isPublished, isPrivate } =
      e.target.elements;

    if (!name || !description) {
      setError("Please fill in all fields");
      return;
    }

    const editProject = await update(selected.id, {
      name: name.value,
      description: description.value,
      namespace: namespace.value,
      url: url.value,
      isPublished: isPublished.checked,
      isPrivate: isPrivate.checked,
    });

    navigate(`/projects/${editProject.slug}`);
  };

  return (
    <div className={styles.form}>
      <h1>Edit Project</h1>
      {error && <Alert type="error">{error}</Alert>}
      <form onSubmit={handleSave}>
        <Input
          label="Name"
          name="name"
          value={selected?.name}
          placeholder="Type here..."
        />
        <Input
          label="Description"
          name="description"
          value={selected?.description}
          placeholder="Type here..."
        />
        <Input
          pattern="\w+(?:\.\w+)+"
          label="Namespace"
          name="namespace"
          value={selected?.namespace}
          placeholder="Type here..."
        />
        <Input
          type="url"
          label="Url"
          name="url"
          value={selected?.url}
          placeholder="Type here..."
        />

        <Checkbox
          label="Gepubliceerd"
          value={selected?.isPublished ?? true}
          name="isPublished"
        />

        <Checkbox
          label="Prive"
          value={selected?.isPrivate ?? true}
          name="isPrivate"
        />
        <Button type="green">Save</Button>
      </form>
    </div>
  );
};

export default EditProject;
