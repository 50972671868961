import ApiService from "./ApiService";

export default class ProjectsService {
  constructor() {
    this.api = new ApiService(`${process.env.REACT_APP_API_URL}/Projects`);
  }

  getAll = async () => {
    return await this.api.get();
  };

  get = async (id) => {
    return await this.api.get(id);
  };

  getBySlug = async (slug) => {
    return await this.api.get(`/${slug}`);
  };

  create = async (project) => {
    return await this.api.post("/", project);
  };

  update = async (id, project) => {
    return await this.api.put(`/${id}`, project);
  };

  order = async (projects) => {
    const order = {};
    projects.forEach((project, index) => {
      order[project.id] = index;
    });

    return await this.api.put(`/Order`, { NewOrder: order });
  };

  setPages = async (projectId, pages) => {
    return await this.api.put(`/${projectId}/Pages`, { pages });
  };

  delete = async (id) => {
    return await this.api.delete(`/${id}`);
  };
}
