import { NavLink } from "react-router-dom";
import useAttributes from "shared/hooks/useAttributes";

const MarkDownLink = ({ node, children }) => {
  const { href, ...props } = node.properties;
  const newProps = useAttributes(props);

  if (
    href?.startsWith("#") ||
    href?.startsWith("http://") ||
    href?.startsWith("https://")
  ) {
    return (
      <a href={href} {...newProps}>
        {children}
      </a>
    );
  }

  return (
    <NavLink to={href} {...newProps}>
      {children}
    </NavLink>
  );
};

export default MarkDownLink;
