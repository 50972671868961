import styles from "./Home.module.scss";
import Loader from "shared/components/loader/Loader";
import { Navigate } from "react-router-dom";
import { useSharedProjects } from "states/Projects";

const Home = () => {
  const { projects } = useSharedProjects();

  return (
    <div className={styles.home}>
      {!projects?.length && <Loader />}
      {projects?.length > 0 && (
        <Navigate to={`/projects/${projects[0].slug}`} />
      )}
    </div>
  );
};

export default Home;
