import { useEffect, useMemo, useState } from "react";
import ProjectsService from "shared/services/ProjectsService";
import { useBetween } from "use-between";

const useProjects = () => {
  const service = useMemo(() => new ProjectsService(), []);
  const [projects, setProjects] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetch = async () => {
    const projects = await service.getAll();
    setProjects(projects);
    setIsLoaded(true);
  };

  useEffect(() => {
    fetch();
  }, [service]);

  const create = async (data) => {
    const project = await service.create(data);
    setProjects((projects) => [...projects, project]);

    setSelected(project);
    return project;
  };

  const update = async (id, data) => {
    const project = await service.update(id, data);
    setProjects((projects) => projects.map((x) => (x.id === id ? project : x)));

    setSelected(project);
    return project;
  };

  const remove = async (id) => {
    if (selected?.id === id) {
      setSelected(null);
    }

    await service.delete(id);
    setProjects((projects) => projects.filter((project) => project.id !== id));
  };

  const select = (slug) => {
    if (slug === "not-found") {
      const proj = NotFoundProject;
      setSelected(proj);
      return proj;
    }

    const project = projects.find((project) => project.slug === slug);
    setSelected(project);

    return project;
  };

  const setPages = async (id, pages) => {
    const currentProject = projects.find((project) => project.id === id);
    const newPages = await service.setPages(id, pages);
    currentProject.pages = newPages;

    setProjects((projects) =>
      projects.map((project) => (project.id === id ? currentProject : project))
    );

    setSelected(currentProject);
    return currentProject;
  };

  const order = async (projects) => {
    const newProjects = await service.order(projects);
    setProjects(newProjects);

    return newProjects;
  };

  return {
    fetch,
    projects,
    isLoaded,
    selected,

    // Methods
    create,
    update,
    remove,
    setPages,
    order,

    select,
  };
};

export const useSharedProjects = () => useBetween(useProjects);

const NotFoundProject = {
  id: 0,
  name: "Not Found",
  slug: "not-found",
  namespace: "maikelvdb.errors.404",
  description:
    "Hmm i did not create this project yet. Sorry! <br /><br /> Here are some random jokes for you, dont blame me if they are bad!",
  pages: [],
};
