import MDEditor from "@uiw/react-md-editor";
import styles from "./Markdown.module.scss";
import MarkDownLink from "./components/link/MarkDownLink";
import MarkDownImage from "./components/image/MarkDownImage";

const Markdown = ({ content, className, ...props }) => {
  return (
    <MDEditor.Markdown
      className={`${styles.markdown} ${className} markdown`}
      {...props}
      transformLinkUri={(url) => {
        if (url.startsWith("#")) {
          return document.URL + url;
        }

        return url;
      }}
      linkTarget={(url) => {
        if (url.startsWith("http://") || url.startsWith("https://")) {
          return "_blank";
        }

        return "_self";
      }}
      source={content}
      components={{
        a: MarkDownLink,
        img: MarkDownImage,
      }}
    />
  );
};

export default Markdown;
