import styles from "./InputDate.module.scss";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InputDate = ({ value, onChange, ...props }) => {
  const [date, setDate] = useState(!!value ? new Date(value) : null);
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
      return;
    }

    onChange(date);
  }, [date, onChange, isInitial]);

  const placeholder = props["placeholder"] ?? "dd-mm-yyyy";
  const maxDate = props["max"] ? new Date(props["max"]) : null;
  const minDate = props["min"] ? new Date(props["min"]) : null;

  return (
    <ReactDatePicker
      className={styles["date-input"]}
      selected={date}
      onChange={(date) => setDate(date)}
      {...props}
      maxDate={maxDate}
      minDate={minDate}
      placeholderText={placeholder}
      dateFormat="dd-MM-yyyy"
    />
  );
};

export default InputDate;
