import { useSharedProjects } from "states/Projects";
import styles from "./TopBar.module.scss";
import { useAuth0 } from "@auth0/auth0-react";
import ContextMenu from "shared/components/context-menu/ContextMenu";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSharedRoute } from "states/Route";

const TopBar = () => {
  const navigate = useNavigate();
  const { selected, remove, projects } = useSharedProjects();
  const { isAuthenticated, authIsLoading, logout } = useAuth0();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const sharedRoutes = useSharedRoute();

  const handleLogOutClick = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleDelete = async () => {
    const conf = window.confirm(
      `Are you sure you want to delete ${selected.name}?`
    );
    if (conf) {
      remove(selected.id);
      navigate("/");
    }
  };

  return (
    <div className={styles["top-bar"]}>
      <div className={styles.brand}>Projects</div>

      <div className={styles.actions}>
        {!authIsLoading && isAuthenticated && (
          <>
            {selected && selected.slug !== "not-found" && (
              <ContextMenu
                activeClass={styles.active}
                trigger={
                  <div className={`${styles.item} ${styles.settings}`}>
                    <span
                      className={`material-symbols-outlined ${styles.icon}`}
                    >
                      settings
                    </span>
                  </div>
                }
                items={[
                  {
                    label: "View project",
                    to: `/projects/${selected.slug}`,
                  },
                  {
                    label: "Edit project details",
                    to: `/admin/projects/${selected.slug}/edit`,
                  },
                  {
                    label: "Manage pages",
                    to: `/admin/projects/${selected.slug}/pages/${
                      sharedRoutes.params.pageNum || 1
                    }`,
                  },
                  { label: "Delete project", onClick: handleDelete },
                ]}
              />
            )}
            <div
              className={`${styles.item} ${styles.logout}`}
              onClick={handleLogOutClick}
            >
              <span className={`material-symbols-outlined ${styles.icon}`}>
                logout
              </span>
            </div>
          </>
        )}
      </div>

      <div className={styles["mobile-menu"]}>
        <div
          className={styles["burger"]}
          onClick={() => setMobileMenuOpen((prev) => !prev)}
        >
          <span className={`material-symbols-outlined ${styles.icon}`}>
            menu
          </span>
        </div>

        <div className={`${styles.menu} ${mobileMenuOpen && styles.open}`}>
          {projects?.length > 0 &&
            projects.map((project) => (
              <NavLink
                key={project.id}
                className={({ isActive }) =>
                  `${styles.item} ${isActive && styles.active}`
                }
                to={`/projects/${project.slug}`}
                onClick={() => setMobileMenuOpen(false)}
              >
                {project.name}
              </NavLink>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
