import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import "./custom.scss";
import Layout from "./shared/components/layout/Layout";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "./shared/components/loader/Loader";
import { AuthenticationGuard } from "./shared/guards/AuthenticationGuard";
import { useSharedProjects } from "states/Projects";

const App = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const projectState = useSharedProjects();
  const { fetch } = useSharedProjects();

  useEffect(() => {
    window.addEventListener("load", function () {
      window.scrollTo(0, 0);
    });

    document.addEventListener("touchmove", function (e) {
      e.preventDefault();
    });

    return () => {
      window.removeEventListener("load", function () {
        window.scrollTo(0, 0);
      });

      document.removeEventListener("touchmove", function (e) {
        e.preventDefault();
      });
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.removeItem("projects_token");
      return;
    }

    getAccessTokenSilently().then((token) => {
      localStorage.setItem("projects_token", token);
      fetch();
    });
  }, [isAuthenticated, getAccessTokenSilently]);

  if (!projectState.isLoaded) {
    return <Loader />;
  }

  const getElement = (route) => {
    const { element, redirectTo, authorize } = route;
    if (!!redirectTo) {
      if (authorize) {
        return (
          <AuthenticationGuard component={() => <Navigate to={redirectTo} />} />
        );
      }

      return <Navigate to={redirectTo} />;
    }

    if (authorize) {
      return <AuthenticationGuard component={element} />;
    }

    const Component = element;
    return <Component />;
  };

  return (
    <Layout>
      <Routes>
        {AppRoutes.map((route, index) => {
          const { element, redirectTo, loader, ...rest } = route;

          return <Route key={index} {...rest} element={getElement(route)} />;
        })}
      </Routes>
    </Layout>
  );
};

export default App;
