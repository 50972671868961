import styles from "./Checkbox.module.scss";

const Checkbox = ({ label, name, value, onChange, ...props }) => {
  const handleOnChange = (e) => {
    if (!onChange) {
      return;
    }

    onChange(e.target.checked);
  };

  return (
    <div className={styles.checkbox}>
      <input
        type="checkbox"
        id={name}
        name={name}
        defaultChecked={value}
        onChange={handleOnChange}
        {...props}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
};

export default Checkbox;
