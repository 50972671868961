import React, { useRef, useState } from "react";
import styles from "./ContextMenu.module.scss";
import useClickOutside from "shared/hooks/useClickOutside";
import { NavLink } from "react-router-dom";

const ContextMenu = ({
  trigger,
  items,
  className,
  offset,
  activeClass = "active",
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useClickOutside(
    ref,
    () => {
      setIsOpen(false);
    },
    isOpen
  );

  if (!items?.length) {
    return <></>;
  }
  const existingClassName = trigger.props.className || "";
  const newClassName = isOpen
    ? `${existingClassName} ${activeClass}`
    : existingClassName;

  const clonedTrigger = React.cloneElement(trigger, {
    className: newClassName,
  });

  return (
    <div
      className={`${styles["context-menu"]} ${className}`}
      {...props}
      ref={ref}
    >
      <div
        className={styles.trigger}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {clonedTrigger}
      </div>

      {isOpen && items?.length ? (
        <div className={styles.menu} style={{ ...offset }}>
          {items.map((item, index) =>
            item.to ? (
              <NavLink
                key={index}
                className={({ isActive }) =>
                  `${styles.item} ${isActive && styles.active}`
                }
                to={item.to}
                onClick={() => setIsOpen(false)}
              >
                {item.label}
              </NavLink>
            ) : (
              <div
                key={index}
                className={styles.item}
                onClick={(e) => {
                  item.onClick(e);
                  setIsOpen(false);
                }}
              >
                {item.label}
              </div>
            )
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ContextMenu;
