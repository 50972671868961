import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Auth0Provider } from "@auth0/auth0-react";
import Modal from "./shared/components/modal/Modal";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

root.render(
  <Auth0Provider
    domain="maikelvdb.eu.auth0.com"
    clientId="2HdSkhuh3eIRgRnunCF6ZBTqXDVkBxFe"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://maikelvdb.eu.auth0.com/api/v2/",
      scope: "read:current_user update:current_user_metadata",
    }}
  >
    <BrowserRouter basename={baseUrl}>
      <Modal />
      <App />
    </BrowserRouter>
  </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
