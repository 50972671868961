import Home from "./components/home/Home";
import CreateProject from "./components/admin/projects/create/CreateProject";
import Project from "./components/project/Project";
import EditProject from "./components/admin/projects/edit/EditProject";
import PagesProject from "./components/admin/projects/pages/PagesProject";

const AppRoutes = [
  {
    index: true,
    element: Home,
  },
  {
    element: Project,
    path: "/projects/:slug",
  },
  {
    element: Project,
    path: "/projects/:slug/:pageNum",
  },
  {
    index: true,
    path: "/admin",
    authorize: true,
    redirectTo: "/",
  },
  {
    path: "/admin/projects/create",
    authorize: true,
    element: CreateProject,
  },
  {
    path: "/admin/projects/:slug/edit",
    authorize: true,
    element: EditProject,
  },
  {
    path: "/admin/projects/:slug/pages",
    authorize: true,
    element: PagesProject,
  },
  {
    path: "/admin/projects/:slug/pages/:pageNum",
    authorize: true,
    element: PagesProject,
  },

  {
    path: "*",
    redirectTo: "/projects/not-found",
  },
];

export default AppRoutes;
