import { useState } from "react";
import { useBetween } from "use-between";

const Route = () => {
  const [params, setParams] = useState({});

  return {
    params,
    setParams,
  };
};

export const useSharedRoute = () => useBetween(Route);
